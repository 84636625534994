/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

$backgroundClr: #00013a;
$headingClr: #00013a;
$buttonClr: #fe5723;
$buttonClr_: #03a55a;
$linkClr: #ffffff;
$pFamily: "Poppins", sans-serif;
$bRadius: 15px;
$shadowClr: 0px 5px 30px rgba(55, 84, 170, 0.2);

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 40px;
  font-weight: 500px;
  line-height: 60px;
}

h2 {
  font-size: 36px;
}

h2,
h4 {
  color: $headingClr;
}

p {
  font-size: 16px;
  line-height: 28px;
  font-family: $pFamily;
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .navbar {
    #navbarNav {
      border: 2px solid #ffffff;
      background-color: $backgroundClr;
      border-radius: 5px;
      margin-top: 15px;

      li.nav-item {
        text-align: center;
        padding: 10px 8px;
        margin: 0 !important;
      }
    }
  }

  .hero {
    .container {
      .row {
        .col-lg-2 {
          display: none;
        }
      }
    }
  }

  .selected-projects {
    .background {
      .container {
        .row {
          .features {
            .row {
              a {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .customize {
    margin-top: 180px !important;

    .container {
      .row {
        height: 700px;

        .col-md-5 {
          img.shape_1 {
            margin-left: -140px;
          }

          img.shape_2 {
            margin-left: -80px;
            z-index: -1;
          }

          img.images {
            margin-top: -100px;
            margin-left: 20px;
          }
        }

        .col-md-5 {
          .card {
            max-width: 325px;
          }
        }
      }
    }
  }

  .tabs {
    position: relative;

    .tabs-content {
      .container {
        .col-md-12 {
          ul.nav {
            li.nav-item {
              width: auto;

              a {
                padding-left: 12px;
                padding-right: 12px;

                &.active {
                  border-bottom-right-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .all-project {
    .container {
      .row {
        .col-12 {
          .row {
            .col-md-6 {
              .thumbnail {
                img.card-img-top {
                  height: 285px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .content-detail-page {
    .container {
      .row {
        .col-xl-9 {
          .figure {
            min-width: 690px !important;
            height: 550px;

            img.figure-img {
              width: 690px;
              height: 550px;
              object-fit: contain;
            }
          }

          img {
            width: 100% !important;
            height: 250px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
// ==========================
.header {
  width: 100%;
  padding-top: 30px;
  position: relative;
  background-color: $backgroundClr;

  .navbar {
    padding: 0;

    ul.navbar-nav {
      li.nav-item {
        &.active {
          a.nav-link {
            color: $linkClr;
          }
        }

        a {
          &.nav-link {
            color: $linkClr;
            font-size: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;

            &.cool-link::after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              background: $linkClr;
              background: width 0.3s;
            }

            &.cool-link:hover::after {
              width: 100%;
              transition: width 0.3s;
            }
          }
        }
      }
    }
  }
}

.hero {
  width: 100%;
  height: 565px;
  padding-top: 10px;
  position: relative;
  overflow: hidden;
  background-color: $backgroundClr;

  .container {
    .row {
      .hero {
        h4.text-white {
          margin-bottom: 12px;
        }

        h1.text-white {
          margin-bottom: 20px;
        }

        p.text-white {
          margin-bottom: 25px;
          max-width: 460px;
        }
      }

      .col-lg-4 {
        img {
          margin-left: -76px;
          margin-top: -100px;
        }
      }
    }
  }
}

.introduction {
  .container {
    .d-flex {
      .col-10 {
        .images-icon {
          height: 110px;
          padding: 20px 35px;
          border-radius: $bRadius;
          margin-top: -60px;
          overflow: hidden;
          background-color: #ffffff;
          box-shadow: $shadowClr;
        }
      }
    }
  }
}

.spelizing {
  margin-top: 120px;
}

.selected-projects {
  margin-top: 120px;
  height: 700px;

  .heading {
    margin: auto;
    max-width: 1130px;

    .font-weight-bold {
      margin-bottom: 80px;
    }
  }

  .background {
    height: 430px;
    margin-top: 70;
    background-color: $backgroundClr;

    .container {
      .row {
        .features {
          background-color: #ffffff;

          h4,
          h2 {
            text-transform: capitalize;
          }

          .mb-5 {
            font-size: 16px;
          }

          .row {
            .mediaa {
              width: 300px;

              .media-body {
                .color-gray {
                  font-size: 16px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .projects {
          background-color: #ffffff;

          .image {
            position: absolute;
            opacity: 1;
            display: block;
            margin-top: -80px;
            border-radius: 15px;
            transition: 0.5s ease;
            box-shadow: $shadowClr;
          }

          .middle {
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transition: 0.5s ease;
          }
        }

        .projects:hover .image {
          filter: blur(1.2px);
          -webkit-filter: blur(1.2px);
        }

        .projects:hover .middle {
          opacity: 1;
        }
      }
    }
  }
}

.customize {
  margin-top: 60px;
  margin-bottom: 140px;

  .container {
    .row {
      height: 700px;

      img.shape_1 {
        margin-left: -70px;
        margin-top: 130px;
      }

      img.shape_2 {
        margin-left: -30px;
        margin-top: 60px;
        z-index: -1;
      }

      img.images {
        margin-top: -40px;
        margin-left: 50px;
      }
    }
  }
}

.page-section {
  margin-top: 120px;

  .container {
    .text-center {
      h2 {
        font-size: 42px;
        color: $backgroundClr;
        text-transform: uppercase;
      }
    }

    .divider-custom {
      margin: 1.25rem 0 1.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider-custom-line {
        width: 100%;
        max-width: 7rem;
        height: 0.25rem;
        border-radius: 1rem;
        background-color: $backgroundClr;
        border-color: $backgroundClr !important;
      }

      .divider-custom-icon {
        margin: 0 15px;
      }
    }

    .row {
      margin-top: 50px;

      .col-lg-4 {
        .d-flex {
          .icon-contact {
            color: $linkClr;
            display: flex;
            height: 5rem;
            width: 5rem;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            border-radius: 100%;
            background-color: $buttonClr;
          }
        }
      }
    }
  }
}

.footer {
  margin-top: 120px;
  padding-top: 4rem;
  background-color: $backgroundClr;

  .container {
    .row {
      .col-lg-4 {
        h4 {
          color: $linkClr;
        }

        a {
          color: $linkClr;
          font-size: 20px;
          font-family: Poppins;
          display: inline-block;

          &.nav-link:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.copyright {
  padding-top: 3.5rem;
  padding-bottom: 1.25rem;
  background-color: $backgroundClr;
}

.tabs {
  .tabs-content {
    .content-bg {
      overflow: hidden;
      padding-top: 40px;
      background-color: $headingClr;

      .container {
        padding-top: 17px;

        ul.nav {
          text-align: center;
          padding-top: 21px;
          margin-bottom: -2px !important;

          li.nav-item {
            width: 210px;
            margin-right: 14px;

            a {
              padding: 14px 18px;
              color: #ffffff;
              font-size: 20px;
              font-weight: 500;

              &.active {
                color: #00013a;
                background-color: #fff;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

.all-project {
  .container {
    .row {
      .col-12 {
        .row {
          .col-md-6 {
            .card {
              margin-top: 35px;

              .thumbnail {
                img.card-img-top {
                  width: 100%;
                  height: 385px;
                  max-height: 400px;
                  object-fit: cover;
                  border: 2px solid #f1f2f3;
                }

                .middle {
                  position: absolute;
                  opacity: 0;
                  top: 38%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  transition: 0.5s ease;
                }
              }

              .thumbnail:hover .card-img-top {
                filter: blur(1.2px);
                -webkit-filter: blur(1.2px);
              }

              .thumbnail:hover .middle {
                opacity: 1;
              }

              .card-body {
                padding: 0;
                margin-top: 37px;

                h3.card-text {
                  color: #00013a;
                  font-size: 24px;
                  text-transform: capitalize;
                  font-family: "Poppins", sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }
}

.content-detail-page {
  .container {
    padding-top: 17px;

    .heading h1 {
      color: $headingClr;
      font-size: 46px;
      font-weight: 800;
      margin-top: 80px;
      padding-bottom: 30px;
    }

    .row {
      .col-xl-9 {
        .figure {
          min-width: 825px;
          height: 550px !important;

          img.figure-img {
            width: 100%;
            margin-top: 30px;
            object-fit: cover;
            border: 2px solid #f1f2f3;
          }

          figcaption.figure-caption {
            margin-top: 0;
          }
        }

        img {
          margin-top: 30px;
          border-radius: 0.25rem !important;
          width: 825px;
          height: 550px !important;
          object-fit: contain;
          border: 2px solid #f1f2f3;
        }

        figcaption {
          font-size: 90%;
          color: #6c757d;
          margin-top: -10px;
          text-align: center !important;
        }

        h4 {
          color: $headingClr;
          font-size: 20px;
          font-weight: 800;
          margin-top: 30px;
        }

        p {
          font-size: 16px;
          margin-top: 12px;
          line-height: 30px;
          // text-indent: 50px;
        }

        p.indent {
          text-indent: 50px;
        }
      }
    }
  }
}

.screenshot {
  .container {
    width: 850px;

    .heading h4 {
      color: $headingClr;
      font-size: 20px;
      font-weight: 800;
      margin-top: 30px;
      margin-bottom: 30px;
      text-transform: capitalize;
    }

    .row {
      .col-md-12 {
        .row {
          .col-md-3 {
            .card {
              max-width: 450px;
              height: 250px;
              margin: 10px 5px 10px 15px;
              border-radius: 4px;
              overflow: hidden;
              cursor: pointer;
              border: 2px solid #f1f2f3;

              .card-img-top {
                width: 100%;
                height: 250px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .figure {
      margin-top: 30px;

      img.figure-img {
        object-fit: cover;
      }
    }

    h4 {
      color: $headingClr;
      font-size: 20px;
      font-weight: 800;
      margin-top: 60px;
    }

    p {
      font-size: 16px;
      margin-top: 12px;
      line-height: 30px;
    }
  }
}

.share-study-case {
  margin-top: 120px;

  .container {
    .row {
      .col-md-6 {
        h4.text-center {
          font-size: 21px;
          font-family: $pFamily;
          text-transform: capitalize;
        }

        .thumbnail {
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          box-shadow: $shadowClr;

          button {
            @keyframes bounce {
              0%,
              20%,
              60%,
              100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
              }

              40% {
                -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
              }

              80% {
                -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
              }
            }

            img:hover {
              animation: bounce 1.3s;
            }
          }
        }
      }
    }
  }
}

.contact {
  .container {
    .row {
      padding-top: 60px;

      .col-md-3 {
        padding: 40px 28px;
        min-height: 401px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: $backgroundClr;

        h4 {
          color: $linkClr;
          font-weight: normal;
          margin-bottom: 4rem !important;
        }

        h4::after {
          content: "";
          margin: 5px 0;
          display: block;
          width: 25px;
          height: 2px;
          background-color: #ccbbcc;
        }

        .mobile,
        .map-marked {
          p {
            margin: 12px 20px;
            font-size: 12px;
            font-family: Heebo;
            display: inline-block;
          }
        }

        .envelope {
          p {
            margin: 12px 15px;
            font-size: 12px;
            font-family: Heebo;
            display: inline-block;
          }
        }
      }

      .col-md-7 {
        padding: 0 24px 24px 24px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #ccbbcc;
      }
    }
  }
}

.blog {
  .container {
    .row {
      margin-top: 60px;
      margin-left: 0;
      margin-right: 0;

      .col-md-8 {
        padding-right: 0;
        padding-left: 0;
        max-height: 565px;
        overflow: hidden;
        border-radius: 5px;

        figure {
          width: 100%;
          height: 450px;
          object-fit: cover;

          .middle {
            position: absolute;
            opacity: 0;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transition: 0.5s ease;
          }

          .profil {
            position: relative;
            margin-left: 5%;
            bottom: 45px;

            .username {
              margin-left: 5px;

              p {
                margin-bottom: 7px;

                a {
                  color: $linkClr;
                  text-decoration: none;
                }

                a,
                span {
                  font-size: 14px;
                }
              }
            }
          }
        }

        figure:hover .figure-img {
          filter: blur(1.2px);
          -webkit-filter: blur(1.2px);
        }

        figure:hover .middle {
          opacity: 1;
        }
      }

      .col-md-4 {
        padding: 0 30px !important;

        a {
          padding: 2px 8px;
          margin-right: 15px;
          min-width: 100px !important;
          border-radius: 5px;
        }

        span.text-info {
          font-size: 14px;
        }

        h2,
        p {
          padding-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 425.98px) {
  .hero {
    min-height: 800px !important;

    .container {
      .row {
        .col-lg-6 {
          h1 {
            font-size: 32px;
            line-height: 48px;
          }

          p {
            font-size: 15px;
          }

          .d-flex {
            a {
              padding: 14px;
              margin-right: 20px !important;
            }
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 767.98px) {
  .hero {
    min-height: 850px;

    .container {
      .row {
        .col-lg-6 {
          top: -25px;
          padding: 0;
          margin: 15px;

          .d-flex {
            flex-direction: row !important;

            a {
              min-width: 47%;
            }
          }
        }

        .col-lg-4 {
          position: relative;
          top: -15px;
          height: 280px;
          margin: 15px;
          overflow: hidden;
          border-radius: 4px !important;
          background-color: $buttonClr;

          img {
            height: 340px;
            position: absolute;
            top: 30%;
            left: 40%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .selected-projects {
    min-height: 1420px;
    border-bottom: 2px solid #f1f3f9;

    .heading {
      text-align: center;
    }

    .background {
      min-height: 570px;

      .container {
        .row {
          .projweb {
            top: 650px;
            color: #000000 !important;
            background-color: #ffffff !important;

            h4,
            h2 {
              color: #000000 !important;
            }
          }

          .features {
            padding: 15px;
            color: $linkClr;
            background-color: $backgroundClr;

            h4,
            h2 {
              color: $linkClr;
            }
          }

          .projects {
            top: 280px;
            left: -40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .middle {
              top: 50%;
              left: 75%;
              transform: translate(50%, 250%);
              -ms-transform: translate(-50%, -50%);
              transition: 0.5s ease;
            }
          }

          .image-web {
            top: 60px !important;
          }
        }
      }
    }
  }

  .customize {
    margin-top: 60px;

    .container {
      .row {
        height: 500px;

        .p-5 {
          display: none;
        }

        .mt-5 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          top: -60px;
          margin: 80px;

          .card {
            max-width: 395px;
          }
        }
      }
    }
  }

  .interested {
    margin-top: 260px !important;
  }

  .tabs {
    .tabs-content {
      .container {
        .row {
          .col-lg-2 {
            display: none;
          }
        }

        .col-md-12 {
          ul.nav {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 15px;

            li.nav-item {
              width: 100%;

              a {
                padding-left: 12px;
                padding-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  .all-project {
    .container {
      .row {
        .col-12 {
          .row {
            .col-md-6 {
              margin: 10px;
            }
          }
        }
      }
    }
  }

  .contact {
    .container {
      .row {
        margin: 4px;

        .col-md-3 {
          min-height: 300px;
          border-bottom-left-radius: 0;
          border-top-right-radius: 8px;

          .mobile,
          .envelope,
          .map-marked {
            p {
              font-size: 16px;
            }
          }
        }

        .col-md-7 {
          border-top-right-radius: 0;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }

  .content-detail-page {
    .container {
      .row {
        .col-xl-9 {
          .figure {
            min-width: 395px !important;
            height: 250px;

            img.figure-img {
              width: 395px;
              height: 250px;
              object-fit: contain;
            }
          }

          img {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hero {
    .container {
      .row {
        .col-lg-2 {
          .d-flex {
            margin-right: -80px;
          }
        }
      }
    }
  }
}

@media (min-width: 1199.98px) {
  .spelizing {
    .container {
      .row {
        .col-xl-5 {
          margin: 0 30px;
        }
      }
    }
  }
}

.display-text-group,
.read-more-button {
  display: inline-block;
}
